/*
// .react_dates_overrides
*/
@import '../variables';
@import '../functions';
@import '../mixins/buttons';
@import '../mixins/preloader';
@import '../mixins/hacks';
@import '../mixins/direction';

// Will edit everything selected including everything between a range of dates
.CalendarDay__selected_span {
  background: $date-selected-span_bg-color;
  color: $date-selected-span-font-color;
  border: 1px double $dark-color;
}

// Will edit selected date or the endpoints of a range of dates
.CalendarDay__selected {
  background: $date-selected-bg-color;
  color: $date-selected-font-color;
  border: 1px double $dark-color;
}

// Will edit when hovered over. _span style also has this property
.CalendarDay__selected:hover {
  background: $date-selected-hover-bg-color;
  color: $date-selected-hover-font-color;
  border: 1px double $dark-color;
}

// Will edit when the second date (end date) in a range of dates
// is not yet selected. Edits the dates between your mouse and said date
.CalendarDay__selected_span:hover,
.CalendarDay__hovered_span:hover,
.CalendarDay__hovered_span {
  background: $date-selected-span-hover-bg-color;
  color: $date-selected-span-hover-font-color;
  border: 1px double $dark-color;
}

.DateInput_input__focused {
  border-bottom-color: $dark-color;
}

.DayPickerKeyboardShortcuts_show__bottomRight::before {
  border-right-color: $dark-color;
}
